import { DeviceStatus } from '@flowplan/flowplan-shared';
import { Icon } from 'semantic-ui-react';
import statusStyles from '../../../modules/common/components/ListStatus/ListStatus.module.scss';
import { isSignalPoor } from '../../../modules/installations/components/SignalStrength/signal-strength-parsing';
import { CustomTooltip } from '../../../modules/common/components';
import { Box } from '@mui/material';
import { getStatusConnection } from './Status.Connection.Texts';

interface IDeviceStatus {
  status: DeviceStatus;
  signalStrength: number;
  hoursNotSeen: number;
  showIcon: boolean;
}

const StatusConnection = ({ status, hoursNotSeen, showIcon, signalStrength }: IDeviceStatus): JSX.Element => {
  const deviceStatusInfo = getStatusConnection({ hoursNotSeen, status });
  const hasPoorSignal = isSignalPoor(signalStrength);
  const color = hasPoorSignal ? 'yellow' : 'black';
  const connectionIcon = showIcon ? <Icon name={deviceStatusInfo.iconName} color={color} /> : null;
  return (
    <Box className={statusStyles.iconGroup} sx={{display: 'flex', alignItems: 'center'}}>
      {connectionIcon}
      <CustomTooltip title={deviceStatusInfo.statusTitle}>
        <Box className={deviceStatusInfo.statusClassName} sx={{paddingY: '0.3rem', marginTop: '0.3rem'}}>
          {deviceStatusInfo.statusLabel}
        </Box>
      </CustomTooltip>
    </Box>
  );
};

export default StatusConnection;
