import { Color, DeviceStatus, notAvailable } from '@flowplan/flowplan-shared';
import BatteryIcon from '../../assetstore/icons/power/BatteryIcon.png';
import BatteryIconUnknown from '../../assetstore/icons/power/BatteryIconUnknown.png';
import psuflowplan from '../../assetstore/icons/power/psuflowplan.png';
import { isNetworkGSMGT } from '../../Utility/networkTypeCheck';
import { BatteryStatusInformation, getPowerStatusText } from '../../Utility/Texts';
import './BatteryStatus.scss';
import { CustomTooltip } from '../../modules/common/components';
import { ReactNode } from 'react';

interface IBatteryStatus {
  deviceStatus: number;
  firmware: string;
  network: number;
  percentageStatus: number;
  showBatteryInformation: boolean;
}

interface IFillerStyle {
  height: string;
  background?: string;
}

const BatteryStatus = ({
  deviceStatus,
  firmware,
  network,
  percentageStatus,
  showBatteryInformation,
}: IBatteryStatus): JSX.Element => {
  let tooltip: ReactNode = (
    <>
      <span>Battery status:</span>
      <span className='do-not-translate'> {percentageStatus}</span>
      <span>%</span>
    </>
  );
  let powerStatusText = getPowerStatusText({network, percentageStatus})
  let batteryStatusToShow;

  const fillerStyle: IFillerStyle = { height: `${percentageStatus}%` };
  if (percentageStatus > 20) {
    fillerStyle.background = Color.Month;
  } else if (percentageStatus >= 10) {
    fillerStyle.background = Color.Week;
  } else {
    fillerStyle.background = Color.Overdue;
  }

  let batteryComponent: ReactNode = (
    <div className="battery-status-progress-bar do-not-translate" aria-label={powerStatusText}>
      <div className="battery-status-filler" style={fillerStyle} />
        <img className="battery-status" alt={powerStatusText} src={BatteryIcon} />
      </div>)


  if (deviceStatus === DeviceStatus.Pending) {
    tooltip = BatteryStatusInformation.Pending;
    powerStatusText = BatteryStatusInformation.Pending;
    batteryStatusToShow = BatteryIconUnknown;
    batteryComponent = <img className="battery-status" alt={powerStatusText} src={batteryStatusToShow} />;
  }

  const isGSM = isNetworkGSMGT(network);
  if (!showBatteryInformation && !isGSM) {
    powerStatusText = 
      firmware === undefined || firmware === notAvailable
        ? BatteryStatusInformation.FirmwareUnknown
        : BatteryStatusInformation.ActivityInfoNA;
    tooltip = powerStatusText
    batteryStatusToShow = BatteryIconUnknown;
    batteryComponent = <img className="battery-status" alt={powerStatusText}  src={batteryStatusToShow} />;
  }

  if (isGSM) {
    batteryStatusToShow = psuflowplan;
    tooltip = BatteryStatusInformation.GridPowered
    powerStatusText = BatteryStatusInformation.GridPowered
    batteryComponent = <img className="battery-status" alt={powerStatusText} src={batteryStatusToShow} />;
  }

  return (
    <div className="battery-status-container">
      <CustomTooltip title={tooltip}>
        {batteryComponent}
      </CustomTooltip>
    </div>
  );
};

export default BatteryStatus;
