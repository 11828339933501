import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import {
  AccountBalance as AccountBalanceIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Build,
  ChecklistOutlined as ChecklistOutlinedIcon,
  DescriptionTwoTone,
  GppMaybeOutlined as GppMaybeOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  HomeTwoTone,
  MapOutlined as MapOutlinedIcon,
  OfflineBolt as OfflineBoltIcon,
  PeopleAlt as PeopleAltIcon,
  PermContactCalendarOutlined as PermContactCalendarOutlinedIcon,
  PinDropTwoTone,
  Science as ScienceIcon,
  Settings,
  Support as SupportIcon,
  Warehouse as WarehouseIcon,
  WarehouseTwoTone,
} from '@mui/icons-material';
import { List, Tooltip } from '@mui/material';
import { FC, Fragment, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import InstallationsIcon from '../../../../assetstore/icons/InstallationsIcon';
import { useVersionCheck } from '../../../../hooks/VersionCheck';
import { defaultBaseFilter } from '../../../../Models/graphSettings';
import { useStoreActions, useStoreState } from '../../../../Store';
import { IAccess } from '../../../authentication/types/auth-types';
import { CustomTooltip, Dialog, DialogContent, DialogTitle } from '../../../common/components';
import CacheBusterInfo from '../../../common/components/CacheBusting/CacheBusterInfo';
import FpText from '../../../common/components/FpText/FpText';
import DataNotInSystemDialog from '../../../company/containers/DataNotInSystemDialog/DataNotInSystemDialog';
import LowUsageInstallations from '../../../company/containers/LowUsageInstallations/LowUsageInstallations';
import { IFrontendBeamSerialNotInSystem, LowUsageInstallation } from '../../../company/services/company-model';
import KegIcon from '../../../taps/components/Cards/BeerKegSvg';
import NavigationItem from '../../components/NavigationMenu/NavigationItem';
import { PageTitles, Paths } from './navigation-types';

interface NavItem {
  readonly label: ReactNode;
  readonly path?: Paths;
  readonly icon: JSX.Element;
  readonly navigateToPage: boolean;
  readonly condition?: boolean;
  readonly highlighted?: boolean;
  readonly callback?: () => void;
}

interface NavSection {
  readonly title: string | null;
  readonly items: readonly NavItem[];
  readonly visible: boolean;
}

interface Props {
  collapsed?: boolean;
  isSmallScreen?: boolean;
}

type CreateNavItems = {
  activeItem: string;
  flowplanClientGroupId: number;
  flowplanProductTypeId: number;
  access: IAccess;
  notInSystemCallback: () => void;
  lowUsageCallback: () => void;
  beamsSerialNotInSystem: IFrontendBeamSerialNotInSystem[];
  lowUsageInstallations: LowUsageInstallation[];
  setSelectedInstallation: (filter: IDeviceView) => void;
};

const createNavItems = ({
  access,
  activeItem,
  beamsSerialNotInSystem,
  flowplanClientGroupId,
  flowplanProductTypeId,
  lowUsageCallback,
  lowUsageInstallations,
  notInSystemCallback,
  setSelectedInstallation,
}: CreateNavItems): Record<string, NavItem[]> => ({
  general: [
    {
      label: PageTitles.inventoryBeams,
      path: Paths.inventoryBeams,
      icon: <WarehouseIcon />,
      condition: access.inventory.view,
      navigateToPage: true,
    },
    {
      label: PageTitles.users,
      path: Paths.users,
      icon: <PeopleAltIcon />,
      condition: access.users.view,
      navigateToPage: true,
    },
    {
      label: PageTitles.orders,
      path: Paths.orders,
      icon: <AddShoppingCartIcon />,
      condition: access.orders.view && flowplanClientGroupId > 0 && flowplanClientGroupId !== 1,
      navigateToPage: true,
    },
    {
      label: PageTitles.support,
      path: Paths.support,
      icon: <SupportIcon />,
      condition: access.support.view,
      navigateToPage: true,
    },
  ],
  water: [
    {
      label: PageTitles.dashboard,
      path: Paths.dashboard,
      icon: <MapOutlinedIcon />,
      navigateToPage: true,
    },
    {
      label: PageTitles.clients,
      path: Paths.clients,
      icon: <PermContactCalendarOutlinedIcon />,
      condition: access.clients.view,
      navigateToPage: true,
    },
    {
      label: PageTitles.waterfilterLocations,
      path: Paths.waterfilterLocations,
      icon: <HomeOutlinedIcon />,
      condition: access.locations.view,
      navigateToPage: true,
    },
    {
      label: PageTitles.installations,
      path: Paths.installations,
      icon: <InstallationsIcon stroke={activeItem === Paths.installations ? 'white' : 'black'} />,
      condition: access.installations.view,
      navigateToPage: true,
    },
    {
      label: PageTitles.reports,
      path: Paths.reports,
      icon: <DescriptionTwoTone />,
      condition: access.reports.view,
      navigateToPage: true,
    },
    {
      label: PageTitles.workOrders,
      path: Paths.workOrders,
      icon: <ChecklistOutlinedIcon />,
      condition: access.workOrders.view,
      navigateToPage: true,
    },
    {
      label: (
        <>
            <FpText variant='body1' componentType='span'>Not in system</FpText>
            <FpText variant='body1' componentType='span' className='do-not-translate'>{` (${beamsSerialNotInSystem.length})`}</FpText>
        </>
      ),
      icon: <GppMaybeOutlinedIcon color="error" />,
      highlighted: true,
      condition: access.inventory.dataNotInSystem && beamsSerialNotInSystem.length > 0,
      callback: notInSystemCallback,
      navigateToPage: false,
    },
    {
      label:(
        <>
            <FpText variant='body1' componentType='span'>Low usage</FpText>
            <FpText variant='body1' componentType='span' className='do-not-translate'>{` (${lowUsageInstallations.length})`}</FpText>
        </>
      ),
      icon: <GppMaybeOutlinedIcon color="error" />,
      highlighted: true,
      condition: access.inventory.lowUsageInstallations && lowUsageInstallations.length > 0,
      callback: lowUsageCallback,
      navigateToPage: false,
    },
  ],
  taps: [
    {
      label: PageTitles.tapDashboard,
      path: Paths.tapDashboard,
      icon: <HomeTwoTone />,
      navigateToPage: true,
    },
    {
      label: PageTitles.clients,
      path: Paths.clients,
      icon: <PermContactCalendarOutlinedIcon />,
      condition: access.clients.view,
      navigateToPage: true,
    },
    {
      label: PageTitles.tapLocations,
      path: Paths.tapLocations,
      icon: <PinDropTwoTone />,
      navigateToPage: true,
    },
    {
      label: PageTitles.tapStock,
      path: Paths.tapStock,
      icon: <WarehouseTwoTone />,
      navigateToPage: true,
    },
    {
      label: PageTitles.tapKeg,
      path: Paths.tapKeg,
      icon: <KegIcon fillpercentage={50} liquidfill="dark" stroke={activeItem === Paths.tapKeg ? 'white' : 'black'} />,
      navigateToPage: true,
    },
    {
      label: PageTitles.tapTechnician,
      path: Paths.tapTechnician,
      icon: <Build />,
      navigateToPage: true,
    },
    {
      label: PageTitles.tapSettings,
      path: Paths.tapSettings,
      icon: <Settings />,
      condition:
        access.tapSettings.manage &&
        (flowplanProductTypeId === FlowplanProductTypes.Taps ||
          flowplanProductTypeId === FlowplanProductTypes.Everything),
      navigateToPage: true,
    },
  ],
  flowplanAdmin: [
    {
      label: PageTitles.admin,
      path: Paths.admin,
      icon: <AccountBalanceIcon />,
      condition: access.flowplanAdmin,
      callback: () => setSelectedInstallation(defaultBaseFilter),
      navigateToPage: true,
    },
    {
      label: PageTitles.bulkInstallations,
      path: Paths.bulkInstallations,
      icon: <OfflineBoltIcon />,
      condition: access.flowplanAdmin,
      navigateToPage: true,
    },
    {
      label: PageTitles.inventoryBottles,
      path: Paths.inventoryBottles,
      icon: <WarehouseIcon />,
      condition: access.flowplanAdmin,
      navigateToPage: true,
    },
    {
      label: PageTitles.water,
      path: Paths.water,
      icon: <ScienceIcon />,
      condition: access.flowplanAdmin,
      navigateToPage: true,
    },
  ],
  other: [],
});

export const NavigationContent: FC<Props> = memo(({ collapsed = false, isSmallScreen = false }) => {
  const location = useLocation();
  const [navigationCount, setNavigationCount] = useState(0);
  const activeItem = useMemo(() => location.pathname + location.search, [location]);

  useEffect(() => {
    setNavigationCount((prev) => prev + 1);
  }, [location]);

  const {
    showMenu,
    access,
    currentUser,
    flowplanClientGroupId,
    flowplanProductTypeId,
    beamsSerialNotInSystem,
    lowUsageInstallations,
  } = useStoreState((state) => ({
    showMenu: state.authModel.showMenu,
    access: state.authModel.access,
    currentUser: state.authModel.currentUser,
    flowplanClientGroupId: state.adminDataModel.flowplanClientGroupId,
    flowplanProductTypeId: state.adminDataModel.flowplanProductTypeId,
    beamsSerialNotInSystem: state.companyModel.beamSerialNotInSystem,
    lowUsageInstallations: state.companyModel.lowUsageInstallations,
  }));

  const setSelectedInstallation = useStoreActions((actions) => actions.graphSettingsModel.setSelectedInstallation);

  const [showLowUsageDialog, setShowLowUsageDialog] = useState<boolean>(false);
  const [showNotInSystemDialog, setShowNotInSystemDialog] = useState<boolean>(false);

  const handleNotInSystem = useCallback(() => {
    setShowNotInSystemDialog((prev) => !prev);
  }, []);

  const handleLowUsage = useCallback(() => {
    setShowLowUsageDialog((prev) => !prev);
  }, []);

  const navItems = useMemo(
    () =>
      createNavItems({
        activeItem,
        flowplanClientGroupId,
        flowplanProductTypeId,
        access,
        notInSystemCallback: handleNotInSystem,
        lowUsageCallback: handleLowUsage,
        beamsSerialNotInSystem,
        lowUsageInstallations,
        setSelectedInstallation,
      }),
    [
      activeItem,
      flowplanClientGroupId,
      flowplanProductTypeId,
      access,
      handleNotInSystem,
      handleLowUsage,
      beamsSerialNotInSystem,
      lowUsageInstallations,
      setSelectedInstallation,
    ],
  );

  const sections = useMemo<NavSection[]>(
    () => [
      {
        title: 'Water',
        items: navItems.water,
        visible: flowplanProductTypeId !== FlowplanProductTypes.Taps,
      },
      {
        title: 'Taps',
        items: navItems.taps,
        visible: flowplanProductTypeId !== FlowplanProductTypes.WaterFilter,
      },
      {
        title: 'Flowplan Admin',
        items: navItems.flowplanAdmin,
        visible: currentUser.role === RoleType.SUPER_ADMIN,
      },
      {
        title: 'General',
        items: navItems.general,
        visible: true,
      },
      {
        title: 'Other',
        items: navItems.other,
        visible: true,
      },
    ],
    [navItems, flowplanProductTypeId, currentUser.role],
  );

  const { serverVersion, serverVersionDescription, showReleaseDialog, showReload } = useVersionCheck(navigationCount);

  if (!showMenu) return null;

  return (
    <div className="side-menu">
      {sections.map((section, index) => {
        if (!section.visible) return null;

        const visibleItems = section.items.filter(({ condition }) => condition === undefined || condition);
        if (!visibleItems.length) return null;

        const shouldShowCollapsed = collapsed && !isSmallScreen;
        const isFirstVisibleSection = sections.findIndex((s) => s.visible) === index;

        return (
          <Fragment key={index}>
            {section.title && !shouldShowCollapsed && (
              <FpText
                variant="h6"
                sx={{
                  padding: '0px 10px 0px',
                  // Add top margin only to first visible section
                  mt: isFirstVisibleSection ? 3 : 0,
                }}
              >
                {section.title}
              </FpText>
            )}
            <List
              component="nav"
              sx={{
                width: '100%',
                transition: (theme) => theme.transitions.create(['padding', 'width']),
                px: shouldShowCollapsed ? 1 : 2,
              }}
            >
              {visibleItems.map((item) =>
                shouldShowCollapsed ? (
                  <CustomTooltip key={item.path} title={<FpText variant='body1'>{item.label}</FpText>}>
                    <div>
                      <NavigationItem
                        {...item}
                        label=""
                        disableActive={!!item.callback}
                        sx={{
                          minHeight: 48,
                          justifyContent: 'center',
                          px: 1,
                          '& .MuiListItemIcon-root': {
                            minWidth: 0,
                            marginRight: 0,
                          },
                        }}
                      />
                    </div>
                  </CustomTooltip>
                ) : (
                  <NavigationItem
                    key={item.path}
                    {...item}
                    disableActive={!!item.callback}
                    sx={{
                      px: 2,
                      '& .MuiListItemIcon-root': {
                        minWidth: 40,
                      },
                    }}
                  />
                ),
              )}
            </List>
          </Fragment>
        );
      })}

      <Dialog
        open={showReleaseDialog}
        fullScreen
        maxWidth="xl"
        onClose={() => {
          // nothing happens
        }}
      >
        <DialogTitle>New release</DialogTitle>
        <DialogContent>
          <CacheBusterInfo
            hasCheckedCache={showReleaseDialog}
            serverVersion={serverVersion}
            serverVersionDescription={serverVersionDescription}
            showReload={showReload}
          />
        </DialogContent>
      </Dialog>

      <DataNotInSystemDialog
        handleClose={() => setShowNotInSystemDialog(false)}
        showNotInSystemDialog={showNotInSystemDialog}
      />
      <LowUsageInstallations
        handleClose={() => setShowLowUsageDialog(false)}
        showLowUsageInstallations={showLowUsageDialog}
      />
    </div>
  );
});
