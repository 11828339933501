import { dateFormatYMD } from "@hsjakobsen/utilities/lib/date";
import { getDateObject } from "../../../../Utility/time";

type NotInSystemInfoProps = {
  serial: string;
  lastPayloadReceivedAt: string;
  payloadReceivedCount: string
} 

const BeamSerialWithNotInSystemInfo = ({
  serial,
  lastPayloadReceivedAt,
  payloadReceivedCount
}: NotInSystemInfoProps): JSX.Element =>{ return (
    <span>
      <span className="do-not-translate">{`${serial} `}</span>
      <span>(Last seen:</span>
      <span className="do-not-translate">{` ${getDateObject(lastPayloadReceivedAt).format(dateFormatYMD)} `}</span>
      <span>Data:</span>
      <span className="do-not-translate">{` ${payloadReceivedCount})`}</span>
    </span>
  )
}

export default BeamSerialWithNotInSystemInfo
