import { deviceSerialTextPartOne, deviceSerialTextPartTwo, getSerialNumberToShow } from '@flowplan/flowplan-shared';
import { Divider } from '@mui/material';
import InputComponentCanDisable from '../../../../Components/Input/InputComponentCanDisable';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpSelectSearch from '../../../common/components/FpSelectSearch/FpSelectSearch';
import BeamSerialWithNotInSystemInfo from '../../../inventory/components/NotInSystemInfo/NotInSystemInfo';

type FlowplanSerialSelectionProps = {
  exclude?: string[];
  size?: 'small' | 'medium';
};

const FlowplanSerialSelection = ({ exclude = [], size = 'medium' }: FlowplanSerialSelectionProps): JSX.Element => {
  const inventoryAvailable = useStoreState((state) => state.inventoryModel.inventoryAvailable).filter(
    (serial) => !exclude.includes(String(serial.value)),
  );

  const transformedAvailableInventory = inventoryAvailable.map((inventory)=>{
    if(inventory.data && inventory.data.length > 0){
      const [lastPayloadReceivedAt, payloadReceivedCount] = inventory.data
      const optionAsComponent = <BeamSerialWithNotInSystemInfo serial={inventory.name} lastPayloadReceivedAt={lastPayloadReceivedAt} payloadReceivedCount={payloadReceivedCount}  />
      inventory.asComponent = optionAsComponent
    }
    return inventory
  })

  const inputSerial = useStoreState((state) => state.newInstallationModel.inputSerial);
  const isUpdate = useStoreState((state) => state.newInstallationModel.isUpdate);

  const setInputSerial = useStoreActions((actions) => actions.newInstallationModel.setInputSerial);

  return (
    <InputComponentCanDisable
      isDisabled={isUpdate}
      inputValue={getSerialNumberToShow(String(inputSerial))}
      inputText={deviceSerialTextPartOne + deviceSerialTextPartTwo}
    >
      <Divider sx={{ height: 10 }} />
      <FpSelectSearch
        label="Available serials"
        size={size}
        removeWhiteSpaceWhenSearching
        options={transformedAvailableInventory}
        selectedValue={inputSerial}
        setSelectionValue={(value) => {
          console.log(value)
          setInputSerial(value as string);
        }}
      />
    </InputComponentCanDisable>
  );
};

export default FlowplanSerialSelection;
