type SignificantFiguresInput = {
  significantFigures: number;
  input: number;
};

export const getSignificantFigures = ({ input, significantFigures }: SignificantFiguresInput): string => {
  if (!input) {
    return '0';
  }

  const inputToString = input.toString();

  if (/\.0+$/.test(inputToString)) {
    // test if it ends with .0 or .00, etc.
    // use alternative string method:
    // const zeros = inputToString.match(/[0]+$/)[0].length; // count the no. of trailing zeros
    const sigfigs = parseFloat(inputToString).toString().length; // no. of other sig figs
    const zerosNeeded = significantFigures - sigfigs;
    if (zerosNeeded < 0) {
      return parseFloat(parseFloat(inputToString).toPrecision(significantFigures)).toFixed();
    } else {
      return inputToString.substring(0, sigfigs + 1 + zerosNeeded); // +1 for the decimal point
    }
  } else {
    return parseFloat(parseFloat(inputToString).toPrecision(significantFigures)).toString();
  }
};

