import { Fragment } from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { useStoreState } from '../../../../../Store';
import styles from './GraphSectionInfoSection.module.scss';
import GraphSectionUsageElement from './GraphSectionUsageElement';

interface IGraphSectionInfo {
  waterFilterEnabled: boolean;
  maintenanceEnabled: boolean;
  initialInstallDate: string;
}

const GraphSectionInfo = ({
  initialInstallDate,
  maintenanceEnabled,
  waterFilterEnabled,
}: IGraphSectionInfo): JSX.Element => {
  const timePeriodselection = useStoreState((state) => state.graphSettingsModel.graphSettingsTime.timePeriodselection);
  const timePeriodOptions = useStoreState((state) => state.graphSettingsModel.timePeriodOptions);
  const timePeriodString = timePeriodOptions[timePeriodselection];
  const sliderSelection = timePeriodString + ' total';

  const averageLifeTimeFilter = useStoreState((state) => state.deviceDataModel.averageLifeTimeFilter);
  const averageLifeTimeMaintenance = useStoreState((state) => state.deviceDataModel.averageLifeTimeMaintenance);
  const totalCountFilterChanges = useStoreState((state) => state.deviceDataModel.totalCountFilterChanges);
  const totalCountMaintenance = useStoreState((state) => state.deviceDataModel.totalCountMaintenance);
  const periodEvents = useStoreState((state) => state.deviceDataModel.periodEvents);
  const periodUsage = useStoreState((state) => state.deviceDataModel.periodUsage);
  const lifetimeEvents = useStoreState((state) => state.deviceDataModel.lifetimeEvents);
  const lifetimeUsage = useStoreState((state) => state.deviceDataModel.lifetimeUsage);
  return (
    <Segment className={styles.segment}>
      <Header as="h3">Installation data</Header>
      <Grid columns={2} divided="vertically">
        <Grid.Row stretched>
          <GraphSectionUsageElement title={sliderSelection}>
            <span className="do-not-translate">{`${periodUsage}L - ${periodEvents} `}</span>
            <span>events</span>
          </GraphSectionUsageElement>
          <GraphSectionUsageElement title={'Usage since installation (' + initialInstallDate + ')'}>
            <span className="do-not-translate">{`${lifetimeUsage}L - ${lifetimeEvents} `}</span>
            <span>events</span>
          </GraphSectionUsageElement>
        </Grid.Row>
        <Grid.Row stretched>
          {waterFilterEnabled && (
            <Fragment>
              <GraphSectionUsageElement title="Filter changes - Lifetime">
                <span className="do-not-translate">{totalCountFilterChanges}</span>
              </GraphSectionUsageElement>
              <GraphSectionUsageElement title="Filter changes - Average frequency">
                <span className="do-not-translate">{averageLifeTimeFilter}</span>
              </GraphSectionUsageElement>
            </Fragment>
          )}
          {maintenanceEnabled && (
            <Fragment>
              <GraphSectionUsageElement title="Maintenance visits - Lifetime">
                <span className="do-not-translate">{totalCountMaintenance}</span>
              </GraphSectionUsageElement>
              <GraphSectionUsageElement title="Maintenance visits - Average frequency">
                <span className="do-not-translate">{averageLifeTimeMaintenance}</span>
              </GraphSectionUsageElement>
            </Fragment>
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default GraphSectionInfo;
