import { ListItemButton, ListItemText, SvgIconProps, SxProps, Theme } from '@mui/material';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { storeLastVisitedLocation } from '../../../../Utility/LocalStorageFunctions';
import { Paths } from '../../containers/Navigation/navigation-types';

interface NavigationItemProps {
  icon?: React.ReactElement<SvgIconProps>;
  label: ReactNode;
  path?: string;
  navigateToPage: boolean;
  callback?(): void;
  iconColor?: string;
  highlighted?: boolean;
  disableActive?: boolean;
  collapsed?: boolean;
  sx?: SxProps<Theme>;
}

const NavigationItem = React.memo(
  ({
    callback,
    icon,
    label,
    navigateToPage,
    path,
    iconColor = 'inherit',
    highlighted = false,
    disableActive = false,
    collapsed = false,
    sx = {},
  }: NavigationItemProps): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    const allowTranslation = path !== Paths.support

    const isSelected = useMemo(() => {
      if (disableActive || !path) return false;

      // For all other paths, use startsWith but ensure it's a complete segment
      return (
        location.pathname.startsWith(path) && (location.pathname === path || location.pathname.startsWith(`${path}/`))
      );
    }, [disableActive, path, location.pathname]);

    const handleClick = useCallback(() => {
      if (callback) {
        callback();
      }

      if (navigateToPage && path) {
        // Save location for non-root paths
        if (path !== '/') {
          storeLastVisitedLocation(path);
        }

        navigate(path);
      }
    }, [callback, navigateToPage, path, navigate]);

    return (
      <ListItemButton
        onClick={handleClick}
        selected={isSelected}
        sx={{
          borderRadius: '10px',
          color: highlighted ? 'error.main' : 'inherit',
          backgroundColor: isSelected ? 'primary.main' : highlighted ? 'rgba(255, 0, 0, 0.1)' : 'transparent',
          transition: (theme: Theme) => theme.transitions.create(['background-color', 'color']),
          padding: collapsed ? '0' : '8px 13px',
          width: collapsed ? '40px' : 'auto',
          minHeight: '40px',
          display: 'flex',
          justifyContent: collapsed ? 'center' : 'flex-start',
          margin: '4px auto',
          '&:hover': {
            backgroundColor: isSelected ? 'primary.dark' : highlighted ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.04)',
          },
          '&.Mui-selected': {
            backgroundColor: 'primary.main',
            color: '#fff',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          },
        }}
      >
        {icon && (
          <>
            {React.cloneElement(icon, {
              color: isSelected ? 'inherit' : highlighted ? 'error' : 'inherit',
              fontSize: collapsed ? 'small' : 'medium',
            } as SvgIconProps)}
          </>
        )}
        {!collapsed && (
          <ListItemText
            className={!allowTranslation ? 'do-not-translate' : ''}
            primary={label}
            sx={{
              m: 0,
              padding: 0.95,
            }}
          />
        )}
      </ListItemButton>
    );
  },
);

NavigationItem.displayName = 'NavigationItem';

export default NavigationItem;
