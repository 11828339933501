import { Box, LinearProgress } from '@mui/material';
import FpDivider from '../../../../common/components/FpDivider/FpDivider';
import FpText from '../../../../common/components/FpText/FpText';

type Props = {
  installationNumber: number;
  numInstallations: number;
};

const LowUsageProgress = ({ installationNumber, numInstallations }: Props): JSX.Element => {
  const progress = installationNumber === 0 ? 0 : Math.round((installationNumber / numInstallations) * 100);
  return (
    <>
      <FpDivider marginY="1rem" height="0rem" />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 45 }}>
          <FpText variant="body1" sx={{ fontWeight: 'bold' }} className='do-not-translate'>{`${installationNumber}/${numInstallations}`}</FpText>
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </Box>
    </>
  );
};

export default LowUsageProgress;
