import { Fade, Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { forwardRef } from 'react';

const CustomTooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { title, children, arrow, ...rest } = props;
  return (
    <MuiTooltip
      ref={ref}
      title={title}
      placement="top"
      followCursor={true}
      {...rest}
      arrow={arrow}
      TransitionComponent={Fade}
      enterDelay={200}
      leaveDelay={100}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: 'rgba(0,0,0,0.8)',
            color: 'white',
            fontSize: '0.85rem'
          }
        }
      }}
    >
      {/* This is to supress an MUI error in the browser: Invalid prop 'children' supplied to 'Modal' */}
      <span>
        {children}
      </span>
    </MuiTooltip>
  );
});

CustomTooltip.displayName = 'CustomTooltip';

export default CustomTooltip;
