import { Color } from '@flowplan/flowplan-shared';
import filterImage from '../../assetstore/icons/filter/filter.png';
import './FilterStatus.scss';
import { CustomTooltip } from '../../modules/common/components';

interface IFilterStatus {
  centered?: boolean;
  disableTooltip: boolean;
  percentageStatus: number;
  showAsFilter: boolean;
}

interface IFillerStyle {
  height: string;
  background?: string;
}

const FilterStatus = ({ centered, disableTooltip, percentageStatus, showAsFilter }: IFilterStatus): JSX.Element => {
  const percentageStatusToUse = 100 - percentageStatus;
  const statusText = 'Water filter capacity used: ' + percentageStatus + '%';
  const tooltip = (
    <>
      <span>Water filter capacity used:</span>
      <span className='do-not-translate'> {percentageStatus}</span>
      <span>%</span>
    </>
  );

  let conditionalStyle;
  if (percentageStatusToUse > 95) {
    conditionalStyle = {
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
    };
  }
  let fillerStyle: IFillerStyle = { height: `${percentageStatusToUse}%` };
  if (percentageStatusToUse < 0 || percentageStatusToUse > 100) {
    fillerStyle = { height: `${100}%` };
    conditionalStyle = {
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
    };
  }

  if (percentageStatusToUse >= 20) {
    fillerStyle.background = Color.FlowplanBlue;
  } else if (percentageStatusToUse >= 10) {
    fillerStyle.background = Color.Week;
  } else if (percentageStatusToUse < 0) {
    fillerStyle.background = Color.Overdue;
  }

  if (showAsFilter) {
    fillerStyle.background = '#c9c9c8';
  }

  const classNameToUse = centered === undefined ? 'filter-status-list-item' : 'filter-status-indicator';

  return (
    <div className={classNameToUse}>
      <CustomTooltip title={tooltip} disableHoverListener={disableTooltip}>
      <div className="filter-status-progress-bar" aria-label={statusText} style={conditionalStyle}>
        <div className="filter-status-filler" style={fillerStyle} />
        <img className="filter-status-image" alt={statusText} src={filterImage} />
      </div>
      </CustomTooltip>
    </div>
  );
};

export default FilterStatus;
