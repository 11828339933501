import { getSerialNumberToShow } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { BillingStatus } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { action, thunk, thunkOn } from 'easy-peasy';
import _ from 'lodash';
import { getDateObject } from '../../../Utility/time';
import { OptionProp } from '../../common/components/FormikSelect/FormikSelect';
import {
  BillingInventory,
  IInventoryModel,
  InventoryStatus
} from '../types/inventory-types';

export const noDataSortValue = '100000000';
export const defaultInventoryStatus: InventoryStatus = {
  standard: {
    active: 0,
    cancelled: 0,
    free: 0,
    pending: 0,
    total: 0,
  },
  prepaid: {
    active: 0,
    cancelled: 0,
    free: 0,
    pending: 0,
    total: 0,
  },
  subscription: {
    active: 0,
    cancelled: 0,
    free: 0,
    pending: 0,
    total: 0,
  },
};

const inventoryModel: IInventoryModel = {
  inventoryStatus: defaultInventoryStatus,
  billingData: [],

  monthsToBillPrepaid: 0,
  monthsToBillStandard: 0,
  monthsToBillSubscription: 0,

  loadingInventoryData: false,
  inventoryToUse: [],
  inventoryData: [],
  inventoryAvailable: [],

  inventoryShowInSystem: true,

  getBillingInformation: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingInventoryData(true);
    const route = Routes.BeamSerial + 'billing-inventory/' + payload;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route, params: { take: 20000 } });
    actions.setLoadingInventoryData(false);
    if (!requestResponse.success) {
      return;
    }
    const billingInformation = requestResponse.data as BillingInventory;
    const {
      billingDateLast,
      billingDateNext,
      billingIntervalCalculated,
      inventory,
      inventoryStatus,
      monthData } = billingInformation;

    getStoreActions().flowplanClientModel.companyBillingDateInfo({
      billingDateLast,
      billingDateNext,
      billingInterval: billingIntervalCalculated,
    });

    actions.setBillingInformation({ inventory, inventoryStatus, monthData });
  }),

  setBillingInformation: action((state, payload) => {
    const { inventory, inventoryStatus, monthData } = payload;

    state.billingData = monthData;
    state.monthsToBillStandard = _.sumBy(monthData, 'amountStandard');
    state.monthsToBillSubscription = _.sumBy(monthData, 'amountSubscription')
    state.monthsToBillPrepaid = _.sumBy(monthData, 'amountPrepaid');

    const inventoryAvailable: OptionProp[] = [];
    for (const serial of inventory) {
      if (serial.sensor) {
        continue;
      }

      if (serial.billingStatus === BillingStatus.CANCELLED || serial.billingStatus === BillingStatus.DAMAGED) {
        continue;
      }

      const { lastPayloadReceivedAt, payloadReceivedCount, flowplanDeviceId, healthState } = serial;

      const notInSystemInfo =
        payloadReceivedCount > 0 && flowplanDeviceId !== ''
          ? [getDateObject(lastPayloadReceivedAt).format(dateFormatYMD),payloadReceivedCount.toString()] : []


      const flavorText =
        flowplanDeviceId !== ''
          ? getSerialNumberToShow(flowplanDeviceId)
          : '';

      inventoryAvailable.push({
        name: flavorText,
        value: flowplanDeviceId,
        extra: healthState,
        data: notInSystemInfo,
      })
    }

    state.inventoryStatus = inventoryStatus;
    state.inventoryData = inventory;
    state.inventoryToUse = inventory;
    state.inventoryAvailable = inventoryAvailable;
  }),

  setLoadingInventoryData: action((state, payload) => {
    state.loadingInventoryData = payload;
  }),

  toggleInventoryShowInSystem: thunk((actions, payload, { getStoreState }) => {
    const shouldShowInSystem = !getStoreState().inventoryModel.inventoryShowInSystem;
    actions.setInventoryShowInSystem(shouldShowInSystem);

    const inventoryToUse = getStoreState().inventoryModel.inventoryData.filter((inventoryItem) => {
      const isInSystem = inventoryItem.sensor?.id ? true : false;

      if (shouldShowInSystem) {
        return isInSystem || !isInSystem;
      } else {
        return !isInSystem;
      }
    });

    actions.setInventoryToUse(inventoryToUse);
  }),

  setInventoryToUse: action((state, payload) => {
    state.inventoryToUse = payload;
  }),

  setInventoryShowInSystem: action((state, payload) => {
    state.inventoryShowInSystem = payload;
  }),



  onActionsThatUpdateInventory: thunkOn(
    (actions, storeActions) => [
      storeActions.serialsModel.assignSerialToCompany,
      storeActions.serialsModel.assignSerialToClient,
      storeActions.serialsModel.wipeDataNotInSystem,
      storeActions.serialsModel.addSerials,
      storeActions.serialsModel.serialReplacementSwap,
      storeActions.deviceActionsModel.changeSerial,
      storeActions.deviceActionsModel.addSensor,
      storeActions.deviceActionsModel.removeSensor,
      storeActions.deviceActionsModel.removeTapSensor,
      storeActions.deviceActionsModel.addTapSensor
    ],
    async (actions, payload, { getStoreState }) => {
      const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
      await actions.getBillingInformation(flowplanClientIdOverride);
    },
  ),
};

export default inventoryModel;
